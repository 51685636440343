#header {
  width: 100%;
  height: 6vh;
  background-color: #fcc3c3;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 2vw;
  padding-right: 2vw;
  border-width: 0px;
  z-index:1;
}

.headerOuterDiv {
  color: white;
  height:6vh;
}

#headerLeft {
  width: 50%;
  float: left;
}

#headerRight {
  width: 20%;
  float: right;
}

.headerCenterDiv {
  display: flex;
  align-items: center;
  height:6vh;
  width: 100%;
  position: absolute;
}

#pfpDiv {
  height: 120%;
  aspect-ratio: 1 / 1;
  top: -10%;
  display: inline-block;
  vertical-align: top;
  position:relative;
}
#titleDiv {
  width: auto;
  height: 100%;
  top: 0px;
  display: inline-block;
  vertical-align: top;
  position:relative;
}

#pfp {
  object-fit: contain;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  
}

#siteTitle {
  margin-top: 0px;
  color: black;
  font-family: GilbertBold;
  font-size: 40px;
  height: 100%;
  padding-left: 5px;
}

#HowToButton {

  width: 100vw;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.nyhetsbrev {
  color: black;
  text-align: center;
}


#citySelectorDiv {
  width: 50%;
  height: 100%;
  /* top: 0px; */
  display: inline-block;
  /* text-align: left; */
  vertical-align: middle;
  position:relative;
  
  /* padding-top: 1vh; */
}

#langDiv {
  width: 50%;
  height: 100%;
  display: inline-block;
  /* text-align: left; */
  vertical-align: middle;
  position:relative;

}

#citySelector {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border-width: 0px;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 20px;
}

#citySelector option {
  text-align: left;
}

#langButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 130px;
  height: 40px;
  border-radius: 4px;
  border-width: 0px;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 20px;
  padding: 0;
}

#phoneSelections {
  display: none;
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: #fcc3c3;
  padding-bottom: 2vh;
}


