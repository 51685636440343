/* Screen-specifics */

@media only screen and (max-width: 1400px) {
  #headerRight {
    width: 30%;
  }
}

@media only screen and (max-width: 1000px) {
  #socMediaSide {
    display: none;
  }
  #socMediaBottom {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #socialMedia {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .socMedImg {
    width: 20%;
    max-width: 70px;
  }
  .headerCenterDiv {
    display: none;
  }
  #headerLeft {
    width: 70%;
  }

}

@media only screen and (max-width: 900px) {
  #outerLang {
    width: 40%;
    float: right;
  }
  #citySelector {
    width: 100px;
  }
  #langButton {
    width: 100px;
  }
}

/* phone */

@media (min-width: 767px) {
  tbody tr:hover {
    background-color: #f83d3d;
  }
  /* #moreInfoPhone {
    display: none;
  }
  #phoneTitle {
    display: none;
  } */
  .phone {
    display: none;
  }
}
  
@media (max-width: 767px) {
  .footerOuterDiv {
    display: none;
  }
  .footerCenterDiv {
    width: 100%
  }
  #header {
    height: 12vh;
    min-height: 80px;
  }
  #headerLeft {
    width: 100%;
    position: relative;
    text-align: center;
  }
  #pfp {
    position: relative;
  }
  #siteTitle {
    font-size: 35px;
  }  
  #headerRight {
    padding-top: 3px;
    width: 100%;
    position: relative;
  }
  #citySelectorDiv {
    text-align: left;
  }
  #langDiv {
    text-align: right;
  }
  #citySelector {
    position: relative;
    left: 10%;
  }
  #langButton {
    position: relative;
    right: 10%;
  }
  #mainContent {
    padding-top: 0vh;
    padding-bottom: 0vh;
  }
}

@media (max-height: 730px) and (min-width: 767px) {
  #siteTitle {
    font-size: 35px;
  }
}

@media (max-height: 700px) and (min-width: 767px) {
  #siteTitle {
    font-size: 30px;
  }
  /* #HowToButton {

  } */
}

/* @media (max-width: 767px) {
    #siteTitle {
      display: none;
    }
    #title {
      text-align: left;
      float: left;
    }
    #calTitle {
      display:none;
    }
    .headerOuterDiv {
      width: 50%;
    }
    
    .headerCenterDiv {
      width: 50%;
    }
    #outerLang {
      text-align:right;
      padding-right: 0vw;
      padding-top: 0vh;
      margin-left: auto; 
      margin-right: 0;
      position:relative;
      width: 50vw;
    }
    #mainContent {
      background-color: black;
    }
    #calendarDiv {
      width: 100vw;
      position: relative;
      overflow-x: scroll;
    }
    .footerOuterDiv {
      display: none;
    }
    .footerCenterDiv {
      width: 100%
    }
    #aboutHeader {
      text-align: center;
    }
    #outerLang {
      float: right;
      width: 30vw;
      padding-bottom: 1vw;
    }
  } */
  
  
  /* phone */
/* @media only screen and (max-width: 900px) {
  .headerCenterDiv {
    width: 50%;
  }
  .nyhetsbrev {
    display: none;
  }
  #outerLang {
    width: 40%;
    float: right;
  }
  #citySelector {
    width: 100px;
  }
} */
  
  
  
/* @media only screen and (min-height: 300px) and (max-height: 700px) and (max-width: 500px) {
  #langButton {
    height: 7vw;
    width: 25vw;
    font-size: 15px;
    padding-top: 1vw;
    text-align: center
  }
  #citySelector {
    width: 25vw;
    height: 7vw;
  }
  #outerLang {
    display: none;
  }
  #phoneSelections {
    display: block;
  }
} */
  
   /* Weird ratios */
  /* @media only screen and (max-width: 700px) and (min-height: 600px) {
  #header {
    height: 5vh;
  }
} */
  
/* @media only screen and  (max-height: 700px) {
  #header {
    height: 8vh;
  }
} */
  
  
/* @media only screen and (max-height: 850px) and (min-height: 701px) and (max-width: 550px) {
  #langButton {
    height: 10vw;
  }
  #citySelector {
    height: 10vw;
    width: 50px
  }
  #outerLang {
    display: none;
  }
  #phoneSelections {
    display: block;
  }
}

@media only screen and (max-width: 650px) {
  #outerLang {
    display: none;
  }
  #phoneSelections {
    display: block;
  }
  #citySelector {
    width: 100px;
  }
} */