#footer {
  position: relative;
  bottom: 0px;
  background-color: black;
  width: 100%;
  height: auto;
  overflow: auto;
  min-height: 25vh;
  z-index: 3;
}

.footerOuterDiv {
  width: 15%;
  color: white;
  float:left;
  position: relative;
  background-color: black;
  /* height: 100% */
}

.footerCenterDiv {
  width: 70%;
  color: white;
  float:left;
  position: relative;
}

.footerOuterDiv #rssDiv {
  padding-left: 30%;
}

#rssDiv:before {
  content: "";
  background-image: url("../assets/rss.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
}



#rssTitle {
  text-align: left;
  position: relative;
}

#rssFeeds {
  text-align: left;
  position: relative;
}

#aboutDiv {
  padding-left: 5%;
  padding-right: 5%;
}

#srcHeader {
  text-align: left;
  vertical-align: top;
}

#sourcesList {
  text-align: left;
}

#aboutHeader {
  text-align: center;
}

.phoneInfo {
  width: 50%;
  float:left;
  position: relative;
  text-align: center;
}

