/* Fonts */
@font-face {
  font-family: "GilbertBold";
  src: local("GilbertBold"),
   url("../fonts/Gilbert-Bold.otf") format("truetype");
  font-weight: bold;
 }

/* General CSS */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

#root {
  width: 100vw;
}

button {
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  outline: 0;
  padding: 10px 25px;
  text-align: center;
}


h3 {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  letter-spacing: 1px;
}

p {
  font-family: 'Atkinson Hyperlegible', sans-serif;
}

div {
  z-index:1;
}

/* Body */

#mainContent {
  padding-top: 1vh;
  padding-bottom: 1vh;
  background-color: #fce2e2;
  min-height: 70vh;
  text-align: center;
}

#calTitle {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  text-align: center;
}


#popupTop {
  text-align: right;
}

#popupTop p {
  text-align: right;
  padding: 0px;
  top: 0px;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: -20px;
  cursor: pointer;
}

#howToPopup {
  position:absolute;
  background-color: #fce2e2;
  font-size: 20px;
  text-align: center;
  width: 50vw;
  top: 15vh;
  left: 24vw;
  right: 24vw;
  padding: 1vw;
  border: solid black;
  z-index:100;
}

#backgroundCover {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: black;
  opacity: .5;
  z-index:50;
}

/* Table */

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
  border: solid;
}

th {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  background-color: black;
  font-weight: bold;
  text-align: left;
  padding: 10px;
  text-align: center;
  border: solid;
  border-color: black;
  cursor: pointer;
}

td {
  font-family: 'Atkinson Hyperlegible', sans-serif;
  border: 1px solid #2c2c2c;
  text-align: left;
  padding: 10px;
}

[cancelled="True"] {
  text-decoration: line-through;
}

tr {
  cursor: pointer;
}

#dateCell {
  white-space: nowrap;
}

tbody tr:nth-child(even) {
  background-color: #fce2e2;
}
tbody tr:nth-child(odd) {
  background-color: #fcc3c3;
}

a {
  color: white;
}


/* Social Media */

#socialMedia {
  right: 0;
  padding-right: 1vw;
  position: fixed;
  width: 70px;
  z-index: 1;
}

.socMedImg {
  width: 100%;
  z-index: 2;
}

#socMediaBottom {
  display: none;
  text-align: center;
  width: 100%;
}